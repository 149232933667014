import { RouteInfo } from "./horizontal-sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/inicio",
    title: "Inicio",
    icon: "home",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  },{
    path: "/coberturas",
    title: "Coberturas",
    icon: "book-open",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  },{
    path: "/descuentos",
    title: "Descuentos",
    icon: "mdi mdi-credit-card",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  },{
    path: "/credenciales",
    title: "Credenciales",
    icon: "folder",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  },{
    path: "/usuarios",
    title: "Usuarios",
    icon: "user",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  },{
    path: "/conexion",
    title: "Pruebas",
    icon: "settings",
    class: "",
    ddclass: "",
    extralink: false,
    submenu: [
    ],
  }
];
