<div class="row">
    <div class="card card-body">
        <div class="col-12 ms-3">

            <div class="col-12 col-sm-6 mt-3">
                <label class="mb-3">Aseguradora</label>
                <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control col-6 ">
                    <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</option>
                </select>
            </div>
            <div class="col-12 col-sm-6 mt-4">
                <label class="mb-3">Negocio</label>
                <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6 ">
                    <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
                </select>
            </div>
            <div class="col-12 col-sm-6 mt-4">
                <label class="mb-3">Paquete</label>
                <input [(ngModel)]="paquete" type="text" class="form-control" />
            </div>
            
            <div class="col-12 mt-3">
                <button (click)="probarConexion()" class="col-12 col-sm-3 btn btn-primary text-white mt-3" ><i class="fa fa-plus-square me-2"></i>Probar</button>
            </div>
            
        </div>

        <div class="row">
            <div class="col-12">
                
            </div>
        </div>

        <!-- list admin -->
        <div class="col-12 m-4">
          <label *ngIf="result && result['error'] " for="">Error</label>
          <div *ngIf="result && result['error'] " class="col-12" style="margin-top: 50px;">
            <textarea style="color: red" name=""  disabled id="" cols="100" rows="10">{{result['error'] | json}}</textarea>
          </div>
          <label for="">Resultado</label>
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'qualitas'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result['Movimientos'] | json}}</textarea>
          </div>
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'chubb'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result['COT'] | json}}</textarea>
          </div>
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'ps'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result | json}}</textarea>
          </div>
  
  
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'gnp'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result['COTIZACION'] | json}}</textarea>
          </div>
  
  
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'tumomento'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result | json}}</textarea>
          </div>
  
  
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'hdi'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result['cotizacion'] | json}}</textarea>
          </div>
          
          <div class="col-12" style="margin-top: 50px;" *ngIf="aseguradora == 'ana'">
              <textarea name=""  disabled id="" cols="100" rows="10">{{result['transacciones'] | json}}</textarea>
          </div>
          <label for="">Request XML</label>
          <div class="col-12" style="margin-top: 50px;">
            <textarea name=""  disabled id="" cols="100" rows="10">{{result['request_xml']}}</textarea>
          </div>
          <label for="">Response XML</label>
          <div class="col-12" style="margin-top: 50px;">
            <textarea name=""  disabled id="" cols="100" rows="10">{{result['response_xml']}}</textarea>
          </div>
        </div>

        

    </div>
</div>

