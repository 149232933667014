import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { AjustesProvider } from './ajustes';
@Injectable()
export class RestApiProvider {
  url:string;
  private headers:any;

  constructor(    
    private http: HttpClient,
    private _ajustes: AjustesProvider
  ) {
    this.url = environment.IP_CONF
    this._ajustes.cargarSessionStorage();
    this.headers = { 
      'Content-Type': 'application/json', 
      'Cookie': 'Token '+ this._ajustes.sesionStorage.token
    }
  }


  login(username:string, password:string){
    return new Promise((resolve,reject) => {
      this.http.post(
        environment.IP_CONF+'core/api-token-auth/', 
        {'username':username, 'password':password},
        {headers: { 'Content-Type': 'application/json' }}        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  reset_password(password: any, code:string){
    return new Promise((resolve,reject) => {
      this.http.post(
        environment.IP_CONF +'core/reset-password/',
        {password, code},
        {
          headers: { 
            'Content-Type': 'application/json'
          }
        }
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  getUrl(url:string) {
    return new Promise((resolve,reject) => {
      this.http.get(url, {
        headers: this.headers,
        withCredentials: true
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  patchUrl(url:string,data:any){
    return new Promise((resolve,reject) => {
      this.http.patch(url,data,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  get_usuarios(){
    return new Promise((resolve,reject) => {
      this.http.get(environment.IP_CONF + 'usuarios/',
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  existe_email(email:any, id:number){
    return new Promise((resolve,reject) => {
      this.http.post(environment.IP_CONF + 'existe-email/',{"email":email, 'id':id},
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  existe_username(username:any){
    return new Promise((resolve,reject) => {
      this.http.get(environment.IP_CONF + 'existe-username/'+username,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }


  crear_usuario(usuario:any){
    return new Promise((resolve,reject) => {
      this.http.post(environment.IP_CONF +'usuarios/', usuario, {
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
        }
      })
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  } 

  deleteUrl(url:string){
    return new Promise((resolve,reject) => {
      this.http.delete(url,
        {
          headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Token '+ this._ajustes.sesionStorage.token 
          },
          withCredentials: true
        }        
      )
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
}
