<div class="row">
    <div class="card card-body">
      <div class="col-12 ms-3">
          <div class="col-12 col-sm-6 mt-3" >
            <label class="mb-3">Aseguradora</label>
              <select [(ngModel)]="aseguradora" (change)="changeAseguradora()" class="form-control col-6 ">
                  <option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</option>
              </select>
          </div>

          <div class="col-12 col-sm-6 mt-4">
            <label class="mb-3">Negocio</label>
              <select [(ngModel)]="owner" (change)="changeEmpresa()" class="form-control col-6 ">
                  <option *ngFor="let empresa of empresas" [value]="empresa.nombre">{{empresa.nombre}}</option>
              </select>
          </div>

        <div class="col-12 col-sm-6 mt-4">
          <button class="col-12 col-sm-5 offset-md-3 btn btn-info text-white" style="margin: 0 10px 0 0;" (click)="modalEmpresa(empresaModal)"><i class="fa fa-plus-square me-2"></i>Nueva Empresa</button>
          <button class="col-12 col-sm-5 offset-md-3 btn btn-info text-white" style="margin: 0 0 0 10px;" (click)="modalCobertura(configModal, {})"><i class="fa fa-plus-square me-2"></i>Nueva Cobertura</button>
        </div>

      </div>

      <div class="row">
        <div class=" col-12 table-responsive">
            <table class="col-10 table table-sm align-middle">
              <thead>
                <tr>
                  <th>Paquete</th>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cobertura of coberturas; let i = index"  (click)="modalCobertura(configModal, cobertura)">
                  <td style="cursor: pointer;">{{cobertura.paquete}}</td>
                  <td style="cursor: pointer;">{{cobertura.nombre}}</td>
                  <td>
                    <button class="btn" (click)="$event.stopPropagation()">
                      <img (click)="deleteCobertura(cobertura, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer; width: 13px;" >
                    </button>
                  </td>
                </tr>
            </table>
        </div>
      </div>
        
      <!-- list admin -->
      <!-- <div class="row" style="margin: 20px; padding: 0 100px;">
          <div class="col-12">
              <div class="d-flex justify-content-between">
                  <div class="col-sm-4 d-flex">
                      <p style="margin: auto 20px;">Paquete</p>
                    
                  </div>
                  <div class="col-sm-4 d-flex">
                      <p style="margin: auto 20px;">Nombre</p>
                     
                  </div>
                  <div class="col-sm-4 d-flex">
                      <p style="margin: auto 20px;"></p>
                      
                  </div>
              </div>
          </div>
      </div>
      <div class="row" style="margin: 20px; padding: 0 100px;">
          <div class="col-12"  *ngFor="let cobertura of coberturas; let i = index"  (click)="modalCobertura(configModal, cobertura)">
              <div class="d-flex ">
                  <div class="col-sm-4 d-flex" style="cursor: pointer;" >
                      <p style="margin: auto 0; font-weight: 500;">{{cobertura.paquete}}</p>
                  </div>
                  <div class="col-sm-4 d-flex">
                      <p style="margin: auto 20px;">{{cobertura.nombre}}</p>
                  </div>
                
                  <div  (click)="$event.stopPropagation()">
                      <img (click)="deleteCobertura(cobertura, i)" src="../../../assets/images/Market/trash.png" alt="" style="cursor: pointer;" >
                  </div>
              </div>
              <hr>
          </div>
         
        </div> -->
    </div>
</div>


<ng-template #configModal let-modal>
    <div class="modal-header">
        <h5 *ngIf="!cobertura_seleccionada || !cobertura_seleccionada.id" class="modal-title" id="editEmpresaLabel">Agregar Cobertura</h5>
        <h5 *ngIf="cobertura_seleccionada && cobertura_seleccionada.id" class="modal-title" id="editEmpresaLabel">Editar Cobertura</h5>
        <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
      </div>
    
      <div class="modal-body">
          <!-- qualitas -->
          <!-- <div class="row" > -->
          <div class="row" *ngIf="provider == 'quialitas'">
              <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Paquete</label>
              <div class="col-sm-8">
                <input [(ngModel)]="cobertura_seleccionada.paquete" type="text" class="form-control" />
              </div>
            </div> 
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Nombre</label>
              <div class="col-sm-8">
                <input [(ngModel)]="cobertura_seleccionada.nombre" type="text" class="form-control"  />
              </div>
            </div>
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Data</label>
              <div class="col-sm-8">
                <textarea [(ngModel)]="cobertura_seleccionada.data" rows="10" type="text" class="form-control" ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Paquete</label>
            <div class="col-sm-8">
              <input [(ngModel)]="cobertura_seleccionada.paquete" type="text" class="form-control" />
            </div>
          </div> 
          <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Nombre</label>
            <div class="col-sm-8">
              <input [(ngModel)]="cobertura_seleccionada.nombre" type="text" class="form-control"  />
            </div>
          </div>
          <div class="col-sm-12">
            <label class="col-sm-4 col-form-label">Data</label>
            <div class="col-sm-8">
              <textarea [(ngModel)]="cobertura_seleccionada.data" rows="10" type="text" class="form-control" ></textarea>
            </div>
          </div>
        </div>

        <!-- <div class="row" *ngIf="provider == 'ana'"></div>
        <div class="row" *ngIf="provider == 'chubb'"></div>
        <div class="row" *ngIf="provider == 'gnp'"></div>
        <div class="row" *ngIf="provider == 'hdi'"></div>
        <div class="row" *ngIf="provider == 'ps'"></div>
        <div class="row" *ngIf="provider == 'qualitas'"></div> -->


          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeBtnClick()"
            >
              Cancelar
            </button>
    
            <button
              type="button"
              class="btn btn-primary"
              (click)="guardarCobertura()"
            >
              Guardar
            </button>
          </div>
      </div>
</ng-template>




<ng-template #empresaModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="editEmpresaLabel">Agregar Empresa</h5>
        <button type="button" class="btn-close" (click)="closeBtnClick()" aria-label="Close"></button>
      </div>
    
      <div class="modal-body">    
          <div class="row">
            <div class="col-sm-12">
              <label class="col-sm-4 col-form-label">Nombre</label>
              <div class="col-sm-8">
                <input [(ngModel)]="nueva_empresa" type="text" class="form-control" />
              </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="closeBtnClick()"
            >
              Cancelar
            </button>
    
            <button
              type="button"
              class="btn btn-primary"
              (click)="guardarEmpresa()"
            >
              Guardar
            </button>
          </div>
      </div>
</ng-template>


