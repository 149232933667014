import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { usuariosService } from './usuarios.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { FormatoService } from '../formatos/formatos.service';
import { NotifierService, NotifierModule } from 'angular-notifier';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight,
    NotifierModule
  ],
  standalone: true,
  providers: [
    usuariosService,
    FormatoService,
    
  ]
})

export class UsuariosComponent {

  credenciales:any = [];
  owner:string = 'Seleccione una opción';
  aseguradora:string = 'Seleccione una opción';
  nueva_empresa:string = '';
  usuarios:any = [];
  empresas:any = [];
  next:string = '';
  previous:string = '';
  aseguradoras:any = [];
  credencial_seleccionada:any = {};
  credencial_seleccionada_active = true;

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private usuariosService : usuariosService,
    private modalService: NgbModal,
    private credencialesService: FormatoService,
    private notifier_ :NotifierService
  ){
    window.scrollTo(0, 0);
    
    this.usuariosService.getUsuarios().subscribe((response:any) =>{
      this.usuarios = response['results'];
      this.next = response['next'];
      this.previous = response['previous'];
    });

    this.credencialesService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({
        url:null,
        nombre:'Seleccione una opción'
      })
    });
  }

  actualizarUsuario(userprofile:any){
    this.usuariosService.patchUrl(userprofile.url, {'empresa':userprofile.empresa}).subscribe((response:any) =>{
      this.notifier_.notify('success', 'Usuario actualizado');
    }, error =>{
      this.notifier_.notify('danger', 'Ah ocurrido un error, intente nuevamente');
    });

  }

  
}
