import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { FormatosComponent } from './formatos/formatos.component';
import { FullComponent } from './layouts/full/full.component';
import { CredencialesComponent } from './credenciales/credenciales.component';
import { DescuentosComponent } from './descuentos/descuentos.component';
import { ConexionComponent } from './conexion/conexion.component';
import { InicioComponent } from './inicio/inicio.component';
import { UsuariosComponent } from './usuarios/usuarios.component';


export const Approutes: Routes = [
  { 
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'inicio',
        component: InicioComponent,
        data: {
          title: 'Inicio',
          urls: [
          ],
        },
      },{
        path: 'coberturas',
        component: FormatosComponent,
        data: {
          title: 'Coberturas',
          urls: [
            { title: 'Inicio', url: '/inicio' },
          ],
        },
      },{
        path: 'credenciales',
        component: CredencialesComponent,
        data: {
          title: 'Credenciales',
          urls: [
            { title: 'Inicio', url: '/inicio' },
          ],
        },
      },{
        path: 'descuentos',
        component: DescuentosComponent,
        data: {
          title: 'Descuentos',
          urls: [
            { title: 'Inicio', url: '/inicio' },
          ],
        },
      },{
        path: 'conexion',
        component: ConexionComponent,
        data: {
          title: 'Conexion',
          urls: [
            { title: 'Inicio', url: '/inicio' },
          ],
        },
      },{
        path: 'usuarios',
        component: UsuariosComponent,
        data: {
          title: 'Usuarios',
          urls: [
            { title: 'Inicio', url: '/inicio' },
          ],
        },
      },
      { path: '', redirectTo: '/inicio', pathMatch: 'full' },
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
  {
    path: 'login',
    component: LoginComponent,
  }
];