import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { FormatoService } from './descuentos.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-descuentos',
  templateUrl: './descuentos.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight
  ],
  standalone: true,
  providers: [
    FormatoService
  ]
})

export class DescuentosComponent {

  descuentos:any = [];
  owner:string = 'Seleccione una opción';
  aseguradora:string = 'Seleccione una opción';
  nueva_empresa:string = '';
  empresas:any = [];
  aseguradoras:any = [];
  descuento_seleccionado:any = {
    paquete: "",
    nombre: "",
    clave: "",
    suma_asegurada: 0,
    deducible: 0
  };

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private formatoService : FormatoService,
    private modalService: NgbModal
  ){
    window.scrollTo(0, 0);
    
    this.formatoService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({nombre:'Seleccione una opción'});
    });
    
    this.formatoService.getAseguradoras().subscribe((response:any) =>{
      this.aseguradoras = response;
      this.aseguradoras.unshift('Seleccione una opción');
    });
    
  }

  getCoberturas(owner:string){
    this.formatoService.getDescuentos(owner, this.aseguradora).subscribe((response)=>{
      this.descuentos = response;
    }, e =>{
      this.descuentos = [];
    })
  }

  changeEmpresa(){
    if(this.aseguradora && this.owner){
      this.getCoberturas(this.owner);
      this.descuento_seleccionado={};
    }
  }

  changeAseguradora(){
    if (this.owner && this.aseguradora){
      this.getCoberturas(this.owner);
      this.descuento_seleccionado={};
    }
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }

  deleteCobertura(cobertura:any, index:number){
    Swal.fire({
      title: '¿Está seguro?',
      text: "Esta operación no podrá deshacerse",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.formatoService.deleteDescuento(cobertura.id, this.aseguradora).subscribe(() =>{
          this.descuentos.splice(index, 1);
        });
      }
    });
  }


  guardarCobertura(){
    if(this.descuento_seleccionado && this.descuento_seleccionado.id){
      this.formatoService.updateDescuento(this.descuento_seleccionado, this.aseguradora).subscribe((response:any) =>{
        this.closeBtnClick();
      });
    } else {
      this.descuento_seleccionado['owner'] = this.owner;
      this.descuento_seleccionado['aseguradora'] = this.aseguradora;
      this.formatoService.createDescuento(this.descuento_seleccionado, this.aseguradora).subscribe((response:any) =>{
        this.descuentos.unshift(response)
        this.closeBtnClick();
      });
    }
    Swal.fire('Éxito', 'Cobertura guardada exitosamente.', 'success');
  }


  guardarEmpresa(){
    this.formatoService.createEmpresa(this.nueva_empresa).subscribe((response:any) =>{
      this.empresas.unshift(response);
      this.nueva_empresa = '';
      this.closeBtnClick();
    });
    Swal.fire('Éxito', 'Empresa guardada exitosamente.', 'success');
  }


  modalCobertura(modalCobertura:any, cobertura:any){
    this.descuento_seleccionado = cobertura;
    this.modalService.open(modalCobertura, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }

  modalEmpresa(modalEmpresa:any){
    this.modalService.open(modalEmpresa, {
      centered: true,
      backdrop: 'static',
      size:'md'
    });
  }

}
