import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { FormatoService } from './formatos.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl, FormBuilder } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight
  ],
  standalone: true,
  providers: [
    FormatoService
  ]
})

export class FormatosComponent {

  coberturas:any = [];
  owner:string = 'Seleccione una opción';
  aseguradora:string = 'Seleccione una opción';
  nueva_empresa:string = '';

  provider:string = '';

  empresas:any = [];
  aseguradoras:any = [];
  cobertura_seleccionada:any = {
    paquete: "",
    nombre: "",
    clave: "",
    suma_asegurada: 0,
    deducible: 0
  };

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private formatoService : FormatoService,
    private modalService: NgbModal,

    private fb: FormBuilder

  ){
    window.scrollTo(0, 0);
    
    this.formatoService.getEmpresas().subscribe((response:any) =>{
      this.empresas = response;
      this.empresas.unshift({nombre:'Seleccione una opción'});
    });
    
    this.formatoService.getAseguradoras().subscribe((response:any) =>{
      this.aseguradoras = response;
      this.aseguradoras.unshift('Seleccione una opción');
    });
    // switch(this.provider){
    //   case 'quialitas':
    //     this.cobertura_seleccionada = this.fb.group({

    //     })

    //   default:
    //     break;
    // }

  }

  getCoberturas(owner:string){
    this.formatoService.getCoberturas(owner, this.aseguradora).subscribe((response)=>{
      this.coberturas = response;
    }, e =>{
      this.coberturas = [];
    })
  }

  changeEmpresa(){
    if(this.aseguradora && this.owner){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }

  changeAseguradora(){
    if (this.owner && this.aseguradora){
      this.getCoberturas(this.owner);
      this.cobertura_seleccionada={};
    }
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }

  deleteCobertura(cobertura:any, index:number){
    Swal.fire({
      title: '¿Está seguro?',
      text: "Esta operación no podrá deshacerse",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.formatoService.deleteCoberturas(cobertura.id, this.aseguradora).subscribe(() =>{
          this.coberturas.splice(index, 1);
        });
      }
    });
  }


  guardarCobertura(){
    if(this.cobertura_seleccionada && this.cobertura_seleccionada.id){
      this.formatoService.updateCobertura(this.cobertura_seleccionada, this.aseguradora).subscribe((response:any) =>{
        this.closeBtnClick();
      });
    } else {
      this.cobertura_seleccionada['owner'] = this.owner;
      this.formatoService.createCoberturas(this.cobertura_seleccionada, this.aseguradora).subscribe((response:any) =>{
        this.coberturas.unshift(response)
        this.closeBtnClick();
      });
    }
    Swal.fire('Éxito', 'Cobertura guardada exitosamente.', 'success');
  }


  guardarEmpresa(){
    this.formatoService.createEmpresa(this.nueva_empresa).subscribe((response:any) =>{
      this.empresas.unshift(response);
      this.nueva_empresa = '';
      this.closeBtnClick();
    });
    Swal.fire('Éxito', 'Empresa guardada exitosamente.', 'success');
  }


  modalCobertura(modalCobertura:any, cobertura:any){
    this.cobertura_seleccionada = cobertura;
    this.modalService.open(modalCobertura, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }

  modalEmpresa(modalEmpresa:any){
    this.modalService.open(modalEmpresa, {
      centered: true,
      backdrop: 'static',
      size:'md'
    });
  }

}
