import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AjustesProvider } from '../services/ajustes';
import { FormatoService } from './inicio.service';
import { NgbHighlight, NgbModal, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule, FormArray,FormControl } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    FeatherModule,
    NgbHighlight
  ],
  standalone: true,
  providers: [
    FormatoService
  ]
})

export class InicioComponent {

  constructor(
    private _router: Router,
    private _ajustes: AjustesProvider,
    private formatoService : FormatoService,
    private modalService: NgbModal
  ){
    window.scrollTo(0, 0);
 
  }


}
